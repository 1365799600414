
// @ts-nocheck
import { Component, Vue } from 'vue-property-decorator'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import { getLocations, updateLocation } from '@/services/locations.service'

@Component({
  components: {
    SellerHeader,
  },
})
export default class ListLocations extends Vue {
  locationsFiltered: Location[] = []

  mounted() {
    this.getLocations()
  }

  async getLocations() {
    const response = await getLocations()
    this.locationsFiltered = response
  }

  goToCreate() {
    this.$router.push('/seller/administration/seller-create-locations')
  }

  async changeLocationStatus(_, { id, name, address, status }) {
    try {
      await updateLocation(id, { name, address, status: !status })
      this.getLocations()
    } catch (error) {
      console.error(error)
    }
  }
}
